<template>
  <div :class="containerClass">
    <b-row>
      <b-col cols="12">
        <div class="settings-title mb-3 mt-3">
          {{ $t('views.credits-packs.credit-pack-create-update.pack-credits') }}
        </div>
        <template v-for="(item, i) of creditPackItems">
          <credit-pack-form-item
              class="mt-2"
              :key="'credit-pack-item-' + i"
              :index="i"
              :credit-pack-item="item"
              :validator="validator"
              @on:remove-credit-pack-item="removeCreditsPackItem"
          />
        </template>
        <add-credit-pack-link
            class="mt-3"
            text-link="views.credits-packs.credit-pack-create-update.add-credit"
            @on:add-link-click="addCreditsPackItem"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import CreditsPackItem from "@/classes/doinsport/CreditsPackItem";

export default {
  components: {
    CreditPackFormItem: () => import('@form/subscriptions/credit-pack/CreditPackItemForm'),
    AddCreditPackLink: () => import('@custom/subscrtiptions/new/AddItemLink')
  },
  props: {
    validator: {
      type: Boolean,
      default: false,
    },
    creditPackItems: {
      type: Array,
      default: () => [],
    },
    containerClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    removeCreditsPackItem (index) {
      this.creditPackItems.splice(index, 1);
    },
    addCreditsPackItem () {
      this.creditPackItems.push(new CreditsPackItem(null, { recent: true }));
    },
    initCreditPackItems () {
      this.creditPackItems.push(new CreditsPackItem());
    }
  },
  created () {
    this.initCreditPackItems();
  }
}
</script>
<style scoped>

</style>
